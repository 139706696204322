@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  background-color: #fcfdfd;
  color: #3e3e3e;
  overflow-x: hidden !important;
  position: relative;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}
a {
  color: #a34b0d;
  text-decoration: none;
}
a:hover {
  color: #a34b0d;
  text-decoration: none;
}
/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}
*::-webkit-scrollbar-thumb {
  background-color: #e39965;
}
img.brand_logo {
  min-width: 175px;
}
.navbar-sticky--moved-up {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #f9f9fa;
  margin-top: 0 !important;
  -webkit-box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  border-radius: 0 !important;
  z-index: 999;
}
.navbar-sticky--on {
  margin-top: 0;
}
.navbar-sticky--transitioned {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
/* ul.navbar_left {
  margin-left: 26%;
} */
.bal{
  color: #3e3e3e;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: normal;
}
.main_navbar a.nav-link {
  color: #3e3e3e;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: normal;
}
.main_navbar a.nav-link.active,
.main_navbar a.nav-link:hover {
  color: #e58872;
  text-decoration: none;
}
.main_wrapper {
  min-height: 100vh;
}
.innerpages_wrapper {
  min-height: 100vh;
  margin: 50px 0;
  position: relative;
}
.main_navbar {
  margin-top: 15px;
  border-radius: 50px;
  padding: 12px 12px;
  z-index: 999;
  justify-content: space-between;
}
.inner_title_wrapper {
  margin: 35px 0;
}
.inner_title {
  color: #333;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
}
.dash_wrapper {
  border-radius: 50px;
  border: 1px solid rgb(151 151 151 / 16%);
  background: rgba(255, 255, 255, 0.20);
  backdrop-filter: blur(4.5px);
  padding: 40px;
  margin-bottom: 30px;
}
.dash_box {
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(4.5px);
  border: 1px solid rgb(223 223 223 / 50%);
  padding: 30px;
  margin-bottom: 24px;
}
.dash_box_left_flex {
  display: flex;
  align-items: center;
}
.dash_box_left_flex > div {
  width: 100%;
}
.dash_box_left h3 {
  color: #212529;
  text-align: right;
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}
.dash_box_left h4 {
  color: #626262;
  text-align: right;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}
.dash_box_right_flex {
  display: flex;
  align-items: center;
}
.dash_box_right_flex > div {
  width: 100%;
}
.dash_box_right h3 {
  color: #212529;
  text-align: left;
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}
.dash_box_right h4 {
  color: #626262;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}
.tradingview-widget-container {
  height: 100%;
}
.tradingview-widget-container > div {
  height: 100%;
}
.time_card {
  min-width: 60px;
  width: 20%;
  padding: 8px 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.count_value {
  font-size: 32px;
  line-height: 38px;
}
.count_indicator {
  font-size: 12px;
  font-weight: 600;
}
.counter {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  background-blend-mode: overlay;
}
.px3 {
  padding-right: 3rem !important;
}
.affiliate_inpgrp {
  position: relative;
  margin: 14px 0;
}
.affiliate_inpgrp input {
  border-radius: 20px;
  background: #f5f5f7;
  border: 1px solid #f5f5f7;
  padding: 8px 12px;
  color: #212529;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
}
.affiliate_list li a {
  background: #b4b4b4;
  color: #fff;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: 0.2s all;
}
.affiliate_list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 15px;
  justify-content: space-between;
}
.affiliate_list li a:hover {
  background: #e58872;
}
.affiliate_list li a svg {
  fill: #fff;
}
.copy_icon {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
}
.txt_green {
  color: #e58872!important;
}
.txt_red {
  color: #ff6363 !important;
}
.dash_box_right_top {
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 15px;
  margin-bottom: 12px;
}
.dash_box_right_top img {
  border-radius: 100px;
  width: 30px;
}
.dash_box_right_top h4 {
  font-size: 16px;
  font-weight: 600;
}
.flex-dashbox-top b span{
  font-size: 16px;
}
.apy_tooltip {
  width: 250px !important;
}
.custom_tooltip {
  background: #fff !important;
  color: #626262 !important;
  border: 1px solid #ebebeb;
  box-shadow: 0 4px 8px rgba(40, 40, 40, .08);
  opacity: 1 !important;
  z-index: 99;
}
.dash_box_right_bottom > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.dash_box_right_bottom .img_box{
  height: 170px;
  justify-content: center;
}
.dash_box_right_bottom .img_box img{
  max-height: 150px;
}
.dash_box_right_bottom > div label:nth-child(2) {
  font-weight: 500;
}
.dash_box_right_bottom h2 {
  color: #212529;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
}
.dash_box_right_bottom h2 span {
  color: #626262;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}
.primary_btn {
  padding: 6px 20px;
  font-size: 15px;
  text-transform: capitalize;
  color: #fff !important;
  border: 0;
  border-radius: 25px;
  background: linear-gradient(90deg, #e39a65 0%, #e95f8d 100%);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
.primary_btn:hover { 
  background: linear-gradient(180deg, #e39a65 0%, #e95f8d 100%);
}
.primary_btn.dark_btn {
  border: 1px solid #686b72 !important;
  background: transparent !important;
  color: #000 !important;
}
.primary_btn.dark_btn:hover {
  background: linear-gradient(180deg, #e39a65 0%, #e95f8d 100%);
}
.primary_btn_transform:hover {
  transform: translateY(-7px);
}
.button_grp {
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.custom_progress {
  position: relative;
}
.progress_value {
  position: absolute;
  top: -7px;
  right: 0;
  color: #e58872;
  font-size: 14px;
  font-weight: 700;
}
.custom_progress .progress {
  border-radius: 10px;
  background: #ececef;
  height: 10px;
}
.custom_progress .progress,
.progress_note {
  width: 95%;
}
.progress_note {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}
.progress_note label {
  color: #9d9d9d;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.progress_note label:nth-child(2) {
  color: #626262;
  font-weight: 700;
}
.custom_progress .progress-bar {
  background: #e58872;
  border-radius: 10px;
}
.dash_box_right h5 {
  color: #626262;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}
.dash_box_right_large {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
.dash_box_right_large_single {
  display: flex;
  align-items: center;
  gap: 30px;
  position: relative;
}
.dash_box_right_large_single h3 {
  color: #212529;
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
}
.dash_box_right_large_single::after {
  content: "";
  background: #e0e0e0;
  width: 1px;
  height: 63px;
  position: absolute;
  right: -42px;
  top: 0;
}
.dash_box_right_large_single:last-child::after {
  display: none;
}
.dash_box_right_large_single h5 {
  margin-bottom: 18px;
}
.dash_box.dash_box_right.dash_box_right_large {
  padding-top: 50px;
  padding-bottom: 50px;
}
.dash_box.dash_box_right {
  padding-top: 36px;
  padding-bottom: 36px;
  border: 1px solid rgb(0 205 249 / 0%) !important;
  background: linear-gradient(#FEFDFC, #FEFDFC) padding-box, 
  linear-gradient(131deg, #E95F8D 17%, #ffffff00 32%, #ffffff38 57%, #ffffff33 5%, #E39965 88%, #E39965 70%) border-box;
}
.inner_subtitle_wrap h3 {
  color: #e58872;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
}
.inner_subtitle_wrap_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.primary_datatable .rdt_TableHeadRow .rdt_TableCol {
  font-size: 16px;
  font-weight: 500;
  color: #212529;
}
.primary_datatable .rdt_Table,
.primary_datatable .rdt_Table > div {
  background: transparent;
}
.primary_datatable .rdt_TableBody .rdt_TableRow,
.primary_datatable .rdt_TableHeadRow {
  border-bottom: 0;
  background: transparent;
}
.primary_datatable .rdt_Pagination {
  border-top: 0;
  background: transparent;
}
.primary_datatable .rdt_TableBody .rdt_TableRow:nth-child(odd) {
  background: rgb(232 232 232 / 50%);
}
.primary_datatable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  text-transform: capitalize;
}
.primary_datatable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  font-size: 15px;
  font-weight: 400;
  color: #626262;
}

.primary_datatable_chg .rdt_TableBody .rdt_TableRow .rdt_TableCell:nth-last-child(1) >div{
  overflow: visible;
  white-space: unset;
}
.footer {
  background: linear-gradient(#040914, #040914) padding-box, linear-gradient(119deg, #ffffff1a 11%, #ffecec00 20%, #ffffff00 9%, #ffffff2b 57%, #ffffff21 100%) border-box;
  border: 1px solid rgb(0 205 249 / 0%) !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 0 !important;
  backdrop-filter: blur(4.5px);
  padding: 30px 0;
  position: relative;
  z-index: 1;
}
.footer_panel_bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer_panel_bottom p {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.footer_panel_bottom p a {
  color: #e58872;
  font-weight: 500;
}
.footer_menu_links {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 30px;
  margin: 0;
}
.footer_menu_links li {
  position: relative;
}
.footer_menu_links li::after {
  content: "";
  background: #898989;
  width: 1px;
  height: 15px;
  position: absolute;
  top: 6px;
  right: -15px;
}
.footer_menu_links li:last-child:after {
  display: none;
}
.footer_menu_links li a {
  color: #a34b0d;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
}
.footer_menu_links li a:hover{
  color: #aa31f8;
}

p.footer_abt_content {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 171%;
  letter-spacing: -0.03em;
}
.footer_panel_top h3 {
  color: #E19864;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.42px;
  text-transform: capitalize;
  margin-bottom: 15px;
}
.footer_panel_top .navbar-brand img {
  width: 182px;
  margin-bottom: 12px;
}
.footer_quick_links {
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer_quick_links li a {
  color: #fdfdfd;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.42px;
  text-transform: capitalize;
}
.footer_social_links {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  padding: 0;
  padding-right: 70px;
  margin: 0;
}
.footer_social_links li a {
  background: #62626200;
  color: #fff;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: 0.2s all;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.footer_social_links li a:hover {
  background: linear-gradient(90deg, #e39a65 0%, #e95f8d 100%);
}
.footer_social_links li a:hover svg path{
  fill: #000 !important;
}
.footer_social_links li a svg {
  fill: #fff;
}
.footer_panel_top {
  border-bottom: 1px solid #ffffff15;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.auth_btn_grp {
  display: flex;
  gap: 15px;
}
.offcanvas-header .btn-close {
  box-shadow: none;
}
.toolbar_bottom {
  display: none;
}
.page_header {
  position: relative;
  padding-top: 150px;
  z-index: 1;
  padding-bottom: 180px;
}
.page_header::before {
  content: "";
  background: rgba(36, 16, 137, 0.5);
  position: absolute;
  left: -25%;
  width: 50%;
  height: 90%;
  border-radius: 100%;
  fill: rgba(36, 16, 137, 0.5);
  filter: blur(200px);
  z-index: -1;
  top: 10%;
}
.page_header::after {
  content: "";
  background: rgba(62, 22, 13, 0.25);
  position: absolute;
  right: -25%;
  width: 50%;
  height: 70%;
  border-radius: 100%;
  filter: blur(100px);
  z-index: -1;
  top: 50%;
}
.page_header .left_box{
  position: relative;
  z-index: 1;
} 
.page_header .img_box{
  position: absolute;
  right: 0;
  min-width: 1336px;
  top: -107%;
}
.page_header_inner {
  margin-top: 50px;
  position: relative;
}
.primary_btn_with_bg {
  background: #e58872;
  color: #fcfdfd;
  box-shadow: 0px 8px 20px 0px rgb(0 205 249 / 30%);
}
.primary_btn_with_bg:hover {
  background: #fcfdfd;
  color: #e58872;
}
.page_header h1 {
  color: #040914;
  font-weight: 600;
  font-size: 65px;
  line-height: 120%;
  margin-bottom: 30px;
  text-transform: uppercase;
}
.page_header h1 span {
  color: #e58872;
}
.page_header p {
  color: #333;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
  z-index: 10;
  position: relative;
}
.main_wrapper {
  min-height: 100vh;
  z-index: 1;
  position: relative;
}
.section {
  padding: 50px 0;
}
.countdown_panel {
  display: flex;
  align-items: center;
  gap: 70px;
}
.countdown_single h3 {
  color: #e39965;
  font-size: 34px;
  font-weight: 600;
  margin-bottom: 0;
}
.countdown_single p {
  color: #333;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 5px;
}
.countdown_section {
  padding: 0;
  z-index: 10;
  position: relative;
}
.countdown_section .bgcolor{
  background: linear-gradient(#040914, #040914) padding-box,
  linear-gradient(0deg, #494d55 0%, #ffffff00 9%, #ffffff38 57%, #ffffff47 100%) border-box;
  background: linear-gradient(#040914, #040914) padding-box, linear-gradient(154deg, #ffffff30 13%, #ffffff00 19%, #ffffff38 57%, #ffffff33 5%, #ffffff26 88%, #ffffff1c 70%) border-box;
  border: 1px solid rgb(0 205 249 / 0%) !important;
  background: linear-gradient(#ffffff, #ffffff) padding-box, linear-gradient(154deg, #e95f8d 13%, #ffffff00 19%, #ffffff38 57%, #ffffff33 5%, #E39965 88%, #E39965 70%) border-box;
  padding: 50px ;
  padding-right: 0;
  border-radius: 20px;
  position: relative;
  z-index: 1;
}
.countdown_section .bgcolor::before{
    content: "";
    position: absolute;
    background: url("./assets/images/bgshade.png") no-repeat center;
    background-size: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 20px;
    margin: auto;
}
.countdown_section .bgcolor::after{
  content: "";
  left: 0;
  right: -65px;
  top: 0;
  position: absolute;
  width: 1px;
  height: 55%;
  display: block;
  margin: auto;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
}
.main_title {
  color: #1e1e1e;
  font-size: 40px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 40px;
}
.main_title span {
  color: #e58872;
}
.main_para {
  color: #333;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 30px;
}
.main_para b {
  color: #1e1e1e;
  font-weight: 500;
}
.features_panel {
  border-radius: 60px;
  background: #dafff2;
  padding: 50px 80px;
}
.features_panel h3 {
  color: #e58872;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  margin: 30px 0;
}
.features_panel p.main_para {
  max-width: 85%;
}
.staking_works {
  display: flex;
  align-items: flex-start;
  gap: 40px;
  margin-bottom: 30px;
}
.staking_works h2 {
  color: #1e1e1e;
  font-size: 20px;
  font-weight: bold;
  line-height: normal;
}
.staking_calc {
  border-radius: 20px;
  background: none;
  padding: 40px;
}
.staking_calc::before {
  content: "";
  opacity: .5;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  border:1px solid rgb(0 205 249 / 0%) !important;
  background: linear-gradient(#FEFDFC, #FEFDFC) padding-box, linear-gradient(131deg, #E95F8D 17%, #ffffff00 32%, #ffffff38 57%, #ffffff33 5%, #E39965 88%, #E39965 70%) border-box;
}
.faq_accordian {
  background: transparent;
  counter-reset: my-sec-counter;
}
.faq_accordian button {
  box-shadow: none !important;
}
.faq_accordian .accordion-item {
  border: none;
  border-bottom: 1px solid rgba(4, 9, 20, 0.1);
  border-radius: 0;
  background: transparent;
}
.faq_accordian .accordion-item:first-child {
  border-top: 1px solid rgba(4, 9, 20, 0.1);
}
.faq_accordian .accordion-item:last-child {
  margin-bottom:100px;
}
.faq_accordian .accordion-item button {
  background: transparent;
  color: #212529;
  font-size: 22px;
  font-weight: 400;
  line-height: normal;
  padding: 25px 0;
  gap: 60px;
}
.faq_accordian .accordion-button::after {
  background: url("../src/assets/images/faq_icon.png") no-repeat;
  width: 22px;
  height: 22px;
}
.faq_accordian .accordion-button:not(.collapsed)::after {
  transform: rotate(45deg);
}
.faq_accordian .accordion-button::before {
  counter-increment: my-sec-counter;
  content: "0" counter(my-sec-counter); 
  font-weight: 600;
font-size: 20px;
letter-spacing: -0.03em;
color: #e19864;
}
.faq_accordian .accordion-body {
  padding: 10px 0 15px;
  padding-left: 80px;
}
.faq_accordian .accordion-body .main_para {
  margin-bottom: 0;
  max-width: 90%;
}
.image_dropdown {
  width: 100%;
  text-align: left;
  border-radius: 20px;
  background: #eee url("../src/assets/images/select_dropdown.png") no-repeat
    center right 15px !important;
  border: 1px solid #f3f3f3 !important;
  height: 60px;
  padding: 10px 15px;
  color: #040914 !important;
  font-size: 16px;
}
.dropdown-toggle.image_dropdown::after {
  display: none;
}
.image_dropdown img,
.image_dropdown_wrapper .dropdown-menu img {
  margin-right: 10px;
}
.image_dropdown_wrapper .dropdown-menu {
  width: 100%;
  padding: 0;
  height: 330px;
  overflow-y: auto;
}
.image_dropdown_wrapper .dropdown-menu .dropdown-item {
  padding-top: 8px;
  padding-bottom: 8px;
}
.image_dropdown_wrapper .dropdown-menu .dropdown-item.active,
.image_dropdown_wrapper .dropdown-menu .dropdown-item:active,
.image_dropdown_wrapper .dropdown-menu .dropdown-item:focus,
.image_dropdown_wrapper .dropdown-menu .dropdown-item:hover {
  background: rgb(0 184 129 / 80%);
  color: #fff;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.staking_calc input {
  height: 60px;
  border-radius: 20px;
  background: #f3f3f3 !important;
  border: 1px solid #f3f3f3 !important;
  padding: 10px 15px;
  color: #1e1e1e !important;
  font-size: 16px;
  box-shadow: none !important;
}
.staking_calc .input-group .primary_asset_inp {
  height: 60px;
  font-size: 30px;
  border-radius: 20px;
  border: 0 !important;
  background: linear-gradient(90deg, #e39a65 0%, #e95f8d 100%) !important;
}
.MuiSlider-root {
  color: #e58872 !important;
}
.staking_calc label {
  color: #040914;
  font-size: 16px;
  margin-bottom: 10px;
}
.staking_calc [class*="col-"] {
  margin-bottom: 30px;
}
.inactive {
  display: none;
}
.staking_method_btngrp {
  display: flex;
  gap: 10px;
  padding-right: 12px;
  flex-wrap: wrap;
}
.staking_method_btngrp button {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  border-radius: 25px;
  background: transparent;
  /* padding: 12px 15px; */
  white-space: nowrap;
  height: 42px;
  padding: 0 15px;
  color: #040914;
  border: 1px solid rgba(4, 9, 20, 0.4);
}
.staking_method_btngrp button.active {
  color: #fff;
  background: linear-gradient(90deg, #e39a65 0%, #e95f8d 100%);
  border: 1px solid transparent;
}
.staking_result_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 80px;
  border-radius: 20px;
  border: 1px solid transparent;
  background: rgba(238, 238, 238, 0.5);
}
.staking_result_div p {
  color: #3e3e3e;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
}
.staking_result_div h3 {
  color: #e39965;
  font-weight: 600;
  font-size: 32px;
  line-height: normal; 
}
.staking_result_div span {
  color: #b7b7b7;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}
.auth_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 120px 0;
  position: relative;
}
.auth_wrapper .dash_box {
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(4.5px);
  position: relative;
  padding: 50px 80px;
  z-index: 1;
}
.innerpages_wrapper::before {
  content: "";
  background: rgba(62, 22, 13, 0.2);
  position: absolute;
  left: -5%;
  width: 50%;
  height: 90%;
  border-radius: 100%;
  filter: blur(200px);
  z-index: -1;
  top: 10%;
}
.innerpages_wrapper::after {
  content: "";
  background: rgba(36, 16, 137, 0.2);
  position: absolute;
  right: -5%;
  width: 60%;
  height: 80%;
  border-radius: 100%;
  filter: blur(100px);
  z-index: -1;
  top: 20%;
}
.auth_form input.form-control {
  background: #ecfcff !important;
  border: 1px solid rgb(0 205 249 / 30%) !important;
  backdrop-filter: blur(2.5px);
  border-radius: 50px;
  height: 56px;
  padding-left: 25px;
  box-shadow: none !important;
  color: #626262;
}
.auth_form input.form-control::placeholder {
  color: #626262;
}
.auth_form .form-check-input:checked {
  background-color: #e86787;
  border-color: #e86787;
  box-shadow: none !important;
}
.auth_form .form-check-input:focus {
  box-shadow: none !important;
}
.auth_form .primary_btn_with_bg {
  padding: 12px 18px;
  font-size: 18px;
}
ul.nav_after_login {
  display: flex;
  align-items: center;
  gap: 5px;
}
.nav_after_login li {
  position: relative;
}
.nav_after_login li a.nav-link {
  color: #1e1e1e !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.nav_after_login .dropdown svg {
  margin-right: 10px;
}
.notify_active {
  background: #d22102;
  border: 2px solid #fff;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  position: absolute;
  right: -6px;
  top: -4px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.nav_after_login li a.dropdown-item {
  color: #1e1e1e;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.nav_after_login li a.dropdown-item:focus,
.nav_after_login li a.dropdown-item:hover {
  color: #fff;
  background-color: #e58872;
}
.level_top_row .dash_box_right {
  padding-top: 20px !important;
  padding-bottom: 0 !important;
  height: 100%;
}
.level_top_row .dash_box_left {
  height: 100%;
  padding: 10px 30px;
}
.level_top_row [class*="col-"] {
  margin-bottom: 24px;
}
.level_head,
.level_body_row {
  display: flex;
}
.level_head > div:nth-child(1),
.level_body_row > div:nth-child(1) {
  width: 25%;
  margin-right: 80px;
}
.level_head > div:nth-child(2),
.level_body_row > div:nth-child(2) {
  width: 40%;
}
.level_head > div:nth-child(3),
.level_body_row > div:nth-child(3) {
  width: 20%;
}
.level_head > div {
  color: #e58872;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.level_body_row > div {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
}
.level_body_row {
  background: #f0f0f0;
  height: 56px;
  margin-bottom: 40px;
  align-items: center;
  border-radius: 30px;
}
.level_ribbon {
  position: relative;
  background: url("../src/assets/images/level_default.png") left center
    no-repeat;
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e58872;
}
.level_ribbon.active {
  background: url("../src/assets/images/level_active.png") left center no-repeat;
  color: #fff;
}
.level_table {
  margin-top: 50px;
}
.level_head {
  padding-bottom: 30px;
}
.asset_dashbox_flex {
  display: flex;
  align-items: flex-start;
  gap: 60px;
}
.asset_dashbox_flex_line {
  position: relative;
}
.asset_dashbox_flex_left,
.asset_dashbox_flex_right {
  width: 50%;
}
.asset_dashbox_flex_line::after {
  content: "";
  width: 1px;
  height: calc(100% - 60px);
  background: #e0e0e0;
  position: absolute;
  right: 50%;
}
.asset_deposit_innertitle {
  color: #e58872;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}
.primary_warning_alert {
  border-radius: 29px;
  background: #f5f5f7;
  border-color: #f5f5f7;
  display: flex;
  align-items: flex-start;
  gap: 15px;
}
.primary_warning_alert svg {
  min-width: 26px;
  height: 26px;
}
.primary_warning_alert h3 {
  color: #626262;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.primary_warning_alert p {
  color: #626262;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.deposit_notes_list {
  color: #626262;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
  padding-left: 15px;
}
.deposit_notes_list li {
  margin-bottom: 15px;
}
.asset_deposit_grp {
  position: relative;
}
.asset_deposit_grp input {
  padding-right: 40px;
}
.primary_asset_inp {
  height: 45px;
  border-radius: 30px;
  background: #f6f6f6 !important;
  border-color: #e9eced !important;
  padding: 10px 20px;
  box-shadow: none !important;
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
}
.asset_copy_icon {
  position: absolute;
  top: 15px;
  right: 18px;
  cursor: pointer;
}
.deposit_asset_details > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.deposit_asset_details > div label {
  color: #626262;
  font-size: 16px;
  font-weight: 400;
  line-height: 36px;
  text-transform: capitalize;
}
.deposit_asset_details > div label:nth-child(2) {
  color: #212529;
  font-weight: 500;
}
.inner_title_wrapper_flex {
  display: flex;
  align-items: center;
  gap: 10px;
}
.assets_form label {
  color: #626262;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  display: block;
}
.withdrawal_type {
  color: #e58872;
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 14px;
  right: 18px;
}
.deposit_asset_details > div.font_bold {
  margin-top: 30px;
}
.deposit_asset_details > div.font_bold label {
  color: #0b0e11;
  font-weight: 600;
}
label.max_label {
  color: #e58872;
  font-size: 16px;
  cursor: pointer;
  font-weight: 700;
  margin-bottom: 8px;
}
.transfer_modal .modal-dialog {
  max-width: 700px;
}
.primary_modal .modal-header h1 {
  color: #1E1E1E;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
}
.primary_modal .modal-header .btn-close {
  box-shadow: none !important;
}
.asset_modal_btn_grp {
  display: flex;
  gap: 24px;
}
.primary_asset_select {
  height: 45px;
  border-radius: 30px;  
  border-color: #e9eced !important;
  background: #f6f6f6 url("../src/assets/images/select_dropdown.png") no-repeat;
  background-position: right 0.75rem center;
  background-size: 14px 9px !important;
  padding: 10px 20px;
  box-shadow: none !important;
  color: #626262;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
}
.asset_transfer_flex {
  display: flex;
  align-items: center;
  gap: 20px;
}
.trade_user_detail {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}
.trade_user_detail h3 {
  color: #212529;
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: -0.96px;
  margin-bottom: 0;
}
.trade_user_detail img {
  box-shadow: 0 15px 25px 0 #e9e9e9;
  border-radius: 100px;
  width: 72px;
  height: 72px;
}
.copy_trade_row .dash_box {
  height: 100%;
}
.copy_trade_row [class*="col-"] {
  margin-bottom: 24px;
}
.cusform_form_switch .form-check-input {
  box-shadow: none !important;
  border-color: #d7d7d7;
}
.cusform_form_switch .form-check-input:checked {
  background-color: #e58872;
  border-color: #e58872;
}
.primary_tab {
  display: flex;
  gap: 30px;
  padding: 0 0 30px;
  border-bottom: 1px solid #efefef;
}
.primary_tab li button.nav-link {
  border-radius: 50px;
  border: 1px solid #d9d9d9;
  color: #afafaf;
  background: transparent;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: 500;
}
.primary_tab li button.nav-link.active {
  border: 1px solid #d9d9d9;
  background: #d9d9d9;
  color: #0b0e11;
}
.notification_panel_row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #1c1e27;
  padding: 30px 0;
  cursor: pointer;
}
.notification_panel_row:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
.unread_dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin-top: 6px;
  background: #fd2626;
  position: absolute;
}
.read_dot {
  background: #fff;
}
.notification_row_content {
  margin-left: 15px;
}
.notification_row_content h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}
.notification_row_content p {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0;
}
.notification_panel_row p.notify_date {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  color: var(--grey-07);
}
.notification_panel_row_empty > div {
  width: 100%;
  align-items: center;
}
.notification_panel_row > div {
  display: flex;
  align-items: flex-start;
  width: 65%;
}
.no_orders {
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center !important;
  flex-direction: column;
  width: 100% !important;
}
.user_info {
  display: flex;
  align-items: center;
  gap: 30px;
}
.user_info img {
  box-shadow: 0 15px 25px 0 #e9e9e9;
  border-radius: 100px;
  width: 72px;
  height: 72px;
}
.user_info_content h3 {
  color: #0b0e11;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 5px;
}
.user_info_content h5 {
  display: flex;
  align-items: center;
  gap: 30px;
}
.user_info_content h5 span {
  color: #626262;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
}
.user_info_content h6 {
  color: #999999;
  font-size: 13px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
}
.user_info_content h6 span {
  cursor: pointer;
}
.center_line {
  margin: 0 5px;
  font-size: 10px;
}
.custom_file_upload {
  position: relative;
  overflow: hidden;
  background: #e76b84  !important;
  padding: 8px 30px;
  color: #fff !important;
}
.custom_file_upload input[type="file"] {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}
.alertCard h3 {
  font-weight: 700;
}
.refer-icon {
  height: 16px;
}
.custom_nav_tabs {
  gap: 10px;
}
.flex-dashbox-top {
  justify-content: space-between;
}
.crypto-img-name {
  display: inline-flex;
  align-items: center;
  gap: 10px;
}
.custom_nav_tabs button.nav-link {
  color: #e58872;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  border-radius: 20px;
  border: 1px solid #e56e81;
  background: transparent;
  padding: 12px 20px;
  white-space: nowrap;
  min-width: 150px;
}
.custom_nav_tabs button.nav-link.active {
  background: #e56e81;
  color: #fff;
}
.staking_top_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.search_grp {
  position: relative;
}
.search_grp input {
  padding: 12px 20px;
  box-shadow: none !important;
  border-radius: 20px;
  background: #f6f6f6 !important;
  border-color: rgb(233 236 237) !important;
  width: 300px;
}
.search_icon {
  position: absolute;
  top: 16px;
  right: 20px;
  width: 18px;
}
.input-group .primary_asset_select {
  flex: 0 1 auto;
  width: 160px;
}
.alert-success {
  color: #1E1E1E;
  background: rgba(0, 184, 129, 0.3);
}
.wallet_table {
  background: transparent;
}
.wallet_table th {
  font-size: 16px;
  font-weight: 500;
  color: #212529;
  background: transparent;
  border: none;
  padding: 10px 30px;
}
.wallet_table td {
  font-size: 15px;
  font-weight: 400;
  color: #626262;
  vertical-align: middle;
  border: none;
  white-space: nowrap;
  background: transparent;
  padding: 10px 30px;
}
.table_asset {
  display: flex;
  align-items: center;
  gap: 10px;
}
.table_asset img {
  width: 36px;
}
.table_asset span {
  font-size: 15px;
  font-weight: 400;
  color: #626262;
}
.wallet_table td:last-child {
  width: 300px;
}
.wallet_table tbody tr:nth-child(odd) {
  background: rgb(216 220 221 / 50%);
}
.wallet_bal {
  display: flex;
  align-items: center;
  gap: 10px;
}
.wallet_bal h4 {
  margin-bottom: 0;
  font-size: 18px;
}
.wallet_page_right_flex {
  display: flex;
  align-items: center;
  gap: 15px;
}
.wallet_page_right_flex .form-check-input {
  box-shadow: none !important;
  background-color: #11131900;
  border-color: #b8b8b899;
}
.wallet_page_right_flex .form-check-input:checked {
  background-color: #e58872!important;
  border-color: #e58872!important;  
}
.dash_wrapper.dash_wrapper_flex {
  display: flex;
  align-items: flex-start;
  gap: 50px;
  position: relative;
}
.dash_wrapper_flex_left, 
.dash_wrapper_flex_right {
  width: 50%;  
}
.dash_wrapper_flex::after {
  content: "";
  width: 1px;
  height: calc(100% - 60px);
  background: rgb(184 184 184 / 30%);
  position: absolute;
  right: 50%;
}
textarea.form-control.primary_asset_inp {
  height: auto;
  resize: none;
}
.uploaded_files_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #171717;
  padding: 5px 10px;
  border-radius: 5px;
  margin-top: 10px;
}
.uploaded_files_row > div span {
  font-size: 12px;
  width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 5px;
}
.dash_wrapper_flex_left .primary_btn {
  padding: 15px 20px;
}
.dash_wrapper_flex_right .search_grp input {
  width: 100%;
}
.dash_wrapper_flex .inner_subtitle_wrap_flex {
  margin-bottom: 15px;
}
.support_ticket_accordian .accordion-header .accordion-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: none !important;
  background: transparent !important;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}
.support_ticket_accordian .accordion-header .accordion-button > div {
  display: flex;
  gap: 20px;
  margin-right: 30px;
}
.support_ticket_accordian .accordion-button::after {
  position: absolute;
  margin-left: unset;
  right: 0;
}
.ticket_msg_card_user {
  width: 85%;
}
.ticket_msg_card {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(160, 160, 160, 0.35);
  padding: 30px;
  margin-bottom: 24px;
}
.ticket_msg_card_header {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 20px;
}
.ticket_msg_card_admin {
  width: 85%;
  margin-left: auto;
}
.ticket_msg_card_header h4 {
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
}
.ticket_msg_card_header p {
  color: #5e5e5e;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}
.ticket_msg_card_desc p {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 0;
}
.ticket_msg_card i {
  font-size: 24px;
}
.chat_reply {
  position: relative;
}
.chat_reply .primary_asset_inp {
  padding-right: 50px;
}
.chat_reply i {
  position: absolute;
  top: 8px;
  right: 20px;
  cursor: pointer;
  color: #e58872;
  font-size: 20px;
}
.faq_accordian .accordion-item button.primary_btn {
  width: auto;
  padding: 10px 30px;
}
.faq_accordian .accordion-item .asset_modal_btn_grp {
  justify-content: flex-end;
}
.ticket_msg_card_wrapper {
  height: 500px;
  overflow: auto;
  padding-right: 10px;
}
.stake_modal .staking_method_btngrp button {
  height: auto;
  padding: 5px 12px;
}
.deposit_asset_details > div.modal_staking_period {
  flex-direction: column;
  align-items: flex-start;
}

.phone_num .form-control {
  width: 100% !important;
  padding-left: 40px !important;
  background-color: transparent !important;
  border: 0;
}
.phone_num .react-tel-input .selected-flag:hover, 
.phone_num .react-tel-input .selected-flag:focus{
  background-color: transparent;
}
.phone_num .react-tel-input .flag-dropdown{
  background-color: transparent;
  border: 0;
}
.phone_num .react-tel-input .flag-dropdown.open{
  background: transparent;
}
.phone_num .react-tel-input .flag-dropdown.open .selected-flag{
  background: transparent;
}
.ticker_panel {
  border-radius: 20px;
  background: #eee;
  border: 1px solid rgb(0 205 249 / 0%) !important;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 20px 25px;
  margin: 0 25px;
}
.ticker_panel img {
  width: 40px;
  height: 40px;
}
.ticker_content h3 {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0;
  line-height: 30px;
}
.ticker_content h4 {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 16px;
  color: #e39965;
  display: flex;
  gap: 20px;
}
.countdown_section .slick-slider{
  max-width: 77%;
  margin: 0 auto;
}
.ticker_settings .slick-prev{
  left: -30px;
}
.ticker_settings .slick-next {
  right: -30px;
}
.ticker_settings .slick-prev, 
.ticker_settings .slick-next {
  border: 1px solid #03A7D3;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.ticker_settings .slick-prev:hover, 
.ticker_settings .slick-next:hover {
  background: linear-gradient(90deg, #e39a65 0%, #e95f8d 100%);
  border-color:transparent ;
}
.ticker_settings .slick-prev:before, 
.ticker_settings .slick-next:before {
  font-size: 0;
  background-image: url("./assets/images/arw_blue.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 14px;
  height: 11px;
  display: block;
  margin: auto;
}
.ticker_settings .slick-prev:hover::before {
  background-image: url("./assets/images/arw_white.png");
  transform: rotate(180deg);
}
.ticker_settings .slick-next:hover::before {
  background-image: url("./assets/images/arw_white.png");
  transform: rotate(0deg);

}
.ticker_settings .slick-next:before {
  transform: rotate(180deg);
}
.auth_form span.withdrawal_type {
  top: 18px;
  font-size: 20px;
  color: #e58872;
}
.staking_calc.trade_buy {
  padding: 0;
  border-radius: 0;
  box-shadow: none;
  background: transparent;
}
.staking_calc.trade_buy .image_dropdown_wrapper .dropdown-menu {
  height: 200px;
}
.staking_calc.trade_buy .form-group {
  margin-top: 0;
  margin-bottom: 15px;
}
.staking_calc.trade_buy label {
  margin-bottom: 3px;
}
.rc-slider-dot {
  bottom: -5px !important;
  width: 14px !important;
  height: 14px !important;
}
.rc-slider {
  padding-left: 30px;
  padding-right: 30px;
}
.rcslider_custom {
  padding: 0 10px;
}
.staking_method_btngrp button.trade_btn {
  height: auto;
  padding: 8px;
  border: 2px solid #fff;
  background: #ffffff21;
}
button.trade_buy.trade_btn {
  color: #d53d3d;
}
button.trade_sell.trade_btn {
  color: #d53d3d;
}
button.trade_buy.trade_btn.active {
  background: linear-gradient(90deg, #e39a65 0%, #e95f8d 100%);
  border: 1px solid transparent;
  color: #fff;
}
button.trade_sell.trade_btn.active {
  background: #d53d3d;
  border: 1px solid #d53d3d;
  color: #fff;
}
.staking_calc.trade_buy .staking_method_btngrp {
  margin-bottom: 15px;
}
.staking_calc.trade_buy button.primary_btn {
  margin-top: 30px;
  padding: 10px;
  font-size: 18px;
  background: linear-gradient(90deg, #e39a65 0%, #e95f8d 100%);
  border: 1px solid transparent;
  color: #fff !important;
}
button.primary_btn.primary_sell_btn {
  border: 1px solid #d53d3d !important;
  background: #d53d3d !important;
}
.dash_box.trade_top_info {
  padding: 10px 30px;
  display: flex;
  align-items: center;
  gap: 0px;
  justify-content: space-between;
}
.trade_top_info > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.trade_top_info > div label {
  font-weight: 500;
}
.trade_top_info > div label:nth-child(2) {
  color: #e58872;
}
.gap-10 {
  gap: 0 10px;
}


.crypto_box{
  background-color: rgb(239 238 238);
  padding: 5px 10px;
  border-radius: 10px;
  border: 1px solid #ddd9d9;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0 5px;
}
.crypto_box p{
  margin-bottom: 0;
  font-weight: 500;
  color: #000000;
}
.crypto_box span{
  font-size: 15px;
}
.copy_trade_row .slick-prev:before,.copy_trade_row .slick-next:before{
  background-image: url("./assets/images/nav_arrow.png");
  background-repeat: no-repeat;
  width: 10px;
  height: 17px;
  font-size: 0;
  display: block;
}
.copy_trade_row .slick-next:before{
  transform: rotate(180deg);
}
.coins_box > div{
  margin:5px 0;
  background-color: #fff;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
}
.coins_box >div p{
  margin-bottom: 0;
  text-transform: uppercase;
}
.coins_box >div p.text-primary {
  color: #e58872!important;
}
.coins_box .accordion-item{
  margin-bottom: 12px !important;
  border-radius: 10px !important;
  background: rgb(239 238 238);
  border: 1px solid #ddd9d9;
}
.coins_box .accordion-item .accordion-button{
  border-radius: 10px !important;
  background: rgb(239 238 238);
  color: #3e3e3e;
  box-shadow: none !important;
}
.accordion-button::after {
  filter: contrast(0) brightness(0) !important;
}
.menu-list div{
  padding: 12px 0;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 10px;
}
.menu-list div:hover{
  background: linear-gradient(90deg, #e39a65 0%, #e95f8d 100%);
  color: #ffffff;
}
.menu-list div img{
  margin-right: 7px;
}
.navbar_right {
  display: flex;
  align-items: center;
  gap: 10px;
}
.toggletheme_btn {
  background: linear-gradient(138deg, rgba(102, 51, 21, 0.75) 16.75%, rgb(244 237 237 / 75%) 85.1%);
  background: linear-gradient(138deg, rgba(102, 51, 21, 0.75) 16.75%, rgb(205 192 192 / 75%) 85.1%);
  width: 35px;
  height: 35px;
  /* display: grid;
  grid-template-columns: 1fr 1fr; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 0;
  padding: 0;
  overflow: hidden;
}
.toggletheme_btn i{
  height: 100%;
  display: flex;
}
.toggletheme_btn i::before{
  height: fit-content;
  margin: auto;
}
.toggletheme_btn i:nth-child(1){
  color: #fff;
}
.toggletheme_btn i:nth-child(2){
  background: #f9f9f9;
  color: #040914;
}
.react-tel-input .form-control {
  height: 45px !important;
  border-radius: 30px !important;
  background: #f6f6f6 !important;
  border-color: #e9eced !important; 
  box-shadow: none !important;
  color: #626262;
  font-size: 16px !important;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
}
.modal-header .btn-close {
  box-shadow: none !important;
}
.input-group span.primary_asset_inp {
  border: 1px solid;
}
.custom_pagination {
  justify-content: center;
  margin-top: 30px;
}
.custom_pagination .active>.page-link, 
.custom_pagination .page-link.active {
  background-color: #e58872;
  border-color: #e58872;
  color: #fff;
}
.custom_pagination .page-link {
  color: #090808;
  box-shadow: none !important;
}
.blog_title {
  font-size: 20px !important;
  font-weight: 700 !important;
}
.blog_content {
  height: 150px;
  display: block !important;
  margin-bottom: 0 !important;
  overflow-x: auto;
  padding-right: 8px;
}

#root{
  overflow: hidden;
}

.pa_ra{
  font-weight: 400;
font-size: 19px;
color: #000;
}


.land_box{
  position: relative;
  z-index: 1;  
}
.land_box::before {
  content: "";
  background: rgba(36, 16, 137, 0.5);
  position: absolute;
  left: -25%;
  width: 50%;
  height: 90%;
  border-radius: 100%;
  fill: rgba(36, 16, 137, 0.5);
  filter: blur(200px);
  z-index: -1;
  top: 10%;
}
.land_box::after {
  content: "";
  background: rgba(62, 22, 13, 0.1);
  position: absolute;
  right: -25%;
  width: 50%;
  height: 70%;
  border-radius: 100%;
  filter: blur(100px);
  z-index: -1;
  top: 50%;
}
.land_box .box{
  border: 1px solid rgb(0 205 249 / 0%) !important;
  background: linear-gradient(#FEFDFC, #FEFDFC) padding-box,
  linear-gradient(131deg, #E95F8D 17%, #ffffff00 32%, #ffffff38 57%, #ffffff33 5%, #E39965 88%, #E39965 70%) border-box;
  padding: 50px;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  margin: 15px;
  margin-bottom: 40px;
  color: #040914;
}
.land_box .box::before{
  content: "";
  position: absolute;
  background: url("./assets/images/boxbg.png") no-repeat center;
  background-size: cover;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  border-radius: 20px;
  bottom: 0;
  top: 0;
  margin: auto;
}
.land_box .box .sub{
  font-weight: 500;
font-size: 18px;
text-transform: uppercase;
color: #040914;
}
.land_box .box h5{
  font-weight: 600;
font-size: 32px;
line-height: 122%;
color: #e39965;
}
.land_box .box h5 + p{
  color: #333333;
}
.land_box .box .para{
  font-weight: 400;
  font-size: 19px;
}
.land_box .mrgin{
  margin-top: 40px;
  margin-bottom: -40px;
}
.img_box2 img{
  max-height: 515px;
}

.left_orange{
  position: relative;
  z-index: 0;
}
.left_orange::before {
  content: "";
  background: rgba(62, 22, 13, 0.2);
  position: absolute;
  left: -5%;
  width: 50%;
  height: 90%;
  border-radius: 100%; 
  filter: blur(200px);
  z-index: -1;
  top: 10%;
}
.right_blue{
  position: relative;
  z-index: 1;
}
.right_blue::after {
  content: "";
  background: rgba(36, 16, 137, 0.5);
  position: absolute;
  right: -5%;
  width: 60%;
  height: 80%;
  border-radius: 100%;
  filter: blur(100px);
  z-index: -1;
  top: 20%;
}
.imgbg2{
  position: relative;
  z-index: 1;
}
.imgbg2::before{
  content: "";
  position: absolute;
  background: url("./assets/images/img_02_bg.png") no-repeat center;
  width: 100%;
  height: 100%;
  z-index: -1;
  left: 0;
  right: 0;
  opacity: .5;
}
.imgbg3{
  position: relative;
  z-index: -1;
}
.imgbg3::before{
  content: "";
  position: absolute;
  background: url("./assets/images/img_03_bg.png") no-repeat left top; 
  width: 100%;
  height: 1083px;
  z-index: -1;
  left: 0;
  opacity: 0;
  bottom: -330px;
}
.calculator{
  position: relative;
  z-index: 1;
}
.calculatorbg::after{
  content: "";
  background:rgba(62, 22, 13, 0.2);
  position: absolute;
  right: -10%;
  width: 60%;
  height: 70%;
  border-radius: 100%;
  filter: blur(100px);
  z-index: -1;
  top: 20%;
  transform: rotate(55deg);
}
.calculator::before{
  content: "";
  background:rgba(36, 16, 137, 0.2);
  position: absolute;
  left: -10%;
  width: 60%;
  height: 100%;
  border-radius: 100%;
  filter: blur(100px);
  z-index: -1;
  top: 20%;
  transform: rotate(-55deg);
}
.calculatorbg{
  position: relative;
  z-index: 0;
}
.calculatorbg::before{
  content: "";
  background: url("./assets/images/calculatorbg.png") no-repeat right bottom;
  height: 583px;
  width: 100%;
  position: absolute;
  right: 0;
  z-index: 1;
  bottom: 0;
  filter: invert(1) brightness(5);
}
.dropdown-menu[data-bs-popper]{
  right: 0;
  left: unset;
}
.arrows{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 15px;
  justify-content: center;
  cursor: pointer;
  height: fit-content;
  padding: 2px;
  filter: invert(1);
}

.cmsContentStyle h3{
  color: #1e1e1e;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 24px;
}
.cmsContentStyle p{
  margin-bottom: 30px;
}
.cmsContentStyle ul{
  margin: 0 0 30px 0;
  padding: 0;
  list-style: none;
}
.cmsContentStyle ul li{
  margin-bottom: 12px;
}
.cmsContentStyle ul li::before{
  content: "";
  background: url(../src/assets/images/arw_white.png) no-repeat scroll 0 0;
  filter: invert(1);
  display: inline-block;
  width: 14px;
  height: 11px;
  margin-right: 15px;
}
/* Dark Theme Css */
[data-theme="dark"] a{
  color: #e39965 ;
}
[data-theme="dark"] .arrows{
  filter:unset;
}
[data-theme="dark"] .pa_ra{
  color: #fff;
}
[data-theme="dark"] .cmsContentStyle h3{
  color: #fff;
}
[data-theme="dark"] .cmsContentStyle ul li::before{
filter: contrast(0.5) brightness(1.5);
}


[data-theme="dark"] .navbar-toggler{
  filter: unset;
}
[data-theme="dark"] .main_navbar .offcanvas{
  background-color: #0b0e11;
}
[data-theme="dark"] .main_navbar .offcanvas-header .btn-close {
  filter: invert(1);
}
[data-theme="dark"] .toggletheme_btn {
  color: #fff;
  background: linear-gradient(190deg, rgba(102, 51, 21, 0.75) 30.76%, rgba(102, 102, 102, 0.75) 77.24%);
  background: #fff;
}
[data-theme="dark"] .calculatorbg::before{
  background: url("./assets/images/calculatorbg.png") no-repeat right bottom ;
  filter: unset;
}
[data-theme="dark"] .calculator::after{
  background:rgb(62 22 13 / 72%); 
}
[data-theme="dark"] .calculator::before{
  background: rgba(36, 16, 137, 0.2);
}
[data-theme="dark"] .toggletheme_btn i:first-child{
  background: #fff;
  color: #000;
}
[data-theme="dark"] .toggletheme_btn i:nth-child(2){
  background: transparent;
  color: #fff;
}
[data-theme="dark"] .primary_btn.dark_btn {
  color: #fff !important;
}
[data-theme="dark"] .navbar-sticky--moved-up {
  background: #000;
}
[data-theme="dark"] .dash_box.dash_box_right {
  background: linear-gradient(#040914, #040914) padding-box, linear-gradient(154deg, #ffffff30 13%, #ffffff00 19%, #ffffff38 57%, #ffffff33 5%, #ffffff26 88%, #ffffff1c 70%) border-box;
}
[data-theme="dark"] body {
  background: #040914;
  color: #fff;
}


[data-theme="dark"] .page_header h1 {
  color: #fff;
}
[data-theme="dark"] .page_header p,
[data-theme="dark"] .primary_warning_alert p {
  color: #d5d5d5;
}
[data-theme="dark"] body::after { 
  background: url("../src/assets/images/footer_bg_dark.png") no-repeat;
  background-size: cover;
  height: 294px;
}
[data-theme="dark"] .footer_panel_top,
[data-theme="dark"] .dash_box_right_top {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
[data-theme="dark"] .footer_panel_top h3,
[data-theme="dark"] .footer_panel_bottom p a {
  color: #e19864;
}
[data-theme="dark"] .footer_quick_links li a,
[data-theme="dark"] .footer_panel_bottom p,
[data-theme="dark"] .footer_menu_links li a,
[data-theme="dark"] .main_para,
[data-theme="dark"] p.footer_abt_content,
[data-theme="dark"] .staking_calc label,
[data-theme="dark"] .staking_result_div p,
[data-theme="dark"] .image_dropdown_wrapper .dropdown-menu .dropdown-item,
[data-theme="dark"] .bal,
[data-theme="dark"] .main_navbar a.nav-link,
[data-theme="dark"] .nav_after_login li a.nav-link,
[data-theme="dark"] .wallet_table td,
[data-theme="dark"] .table_asset span,
[data-theme="dark"] .deposit_notes_list,
[data-theme="dark"] .assets_form label,
[data-theme="dark"] .primary_datatable .rdt_TableBody .rdt_TableRow .rdt_TableCell,
[data-theme="dark"] .primary_datatable .rdt_Pagination,
[data-theme="dark"] .nav_after_login li a.dropdown-item,
[data-theme="dark"] .user_info_content h5 span,
[data-theme="dark"] .accordion-item,
[data-theme="dark"] .withdrawal_type {
  color: #fff !important;
}
[data-theme="dark"] .nav_after_login .dropdown svg path,
[data-theme="dark"] .nav_after_login li a.nav-link svg path {
  fill: #c7c7c7;
}
[data-theme="dark"] .faq_accordian .accordion-item button,
[data-theme="dark"] .main_title,
[data-theme="dark"] .inner_title,
[data-theme="dark"] .wallet_table th,
[data-theme="dark"] .primary_warning_alert h3,
[data-theme="dark"] .dash_box_right h4 {
  color: #fff;
}
[data-theme="dark"] .main_para b,
[data-theme="dark"] .countdown_single p {
  color: #fff;
}
[data-theme="dark"] .primary_btn_with_bg {  
  border-color: rgb(0 205 249 / 30%) !important;
}

[data-theme="dark"] .countdown_single:nth-child(1) svg path:nth-child(1) {
  stroke: #fff;
}
[data-theme="dark"] .countdown_single:nth-child(2) svg path:nth-child(3),
[data-theme="dark"] .countdown_single:nth-child(2) svg path:nth-child(4) {
  stroke: #fff;
}
[data-theme="dark"] .countdown_single:nth-child(3) svg path:nth-child(2),
[data-theme="dark"] .countdown_single:nth-child(3) svg path:nth-child(3)  {
  stroke: #fff;
}
[data-theme="dark"] .staking_works:nth-child(1) svg path:nth-child(5),
[data-theme="dark"] .staking_works:nth-child(1) svg path:nth-child(6) {
  stroke: #fff;
}
[data-theme="dark"] .staking_works:nth-child(2) svg path:nth-child(1),
[data-theme="dark"] .staking_works:nth-child(2) svg path:nth-child(2) {
  stroke: #fff;
}
[data-theme="dark"] .staking_works:nth-child(3) svg path:nth-child(5) {
  fill: #fff;
  stroke: #fff;
}
[data-theme="dark"] .staking_works h2 {
  color: #e58872;
}
[data-theme="dark"] .staking_calc::before {
  background: linear-gradient(#040914, #040914) padding-box, linear-gradient(154deg, #ffffff30 13%, #ffffff00 19%, #ffffff38 57%, #ffffff33 5%, #ffffff26 88%, #ffffff1c 70%) border-box;
  border: 1px solid rgb(0 205 249 / 0%) !important;
}
[data-theme="dark"] .form-text {
  color: #c0c0c0;
}
[data-theme="dark"] .staking_result_div {
  background: linear-gradient(#040914, #040914) padding-box, linear-gradient(119deg, #ffffff1a 11%, #000 20%, #ffffff00 9%, #ffffff2b 57%, #ffffff21 100%) border-box;
  border: 1px solid rgb(0 205 249 / 0%) !important;
}
[data-theme="dark"] .staking_calc input {
  background: #040914 !important;
  border: 1px solid #222630 !important;
  color: #fff !important;
}
[data-theme="dark"] .image_dropdown {
  background: #040914 url("../src/assets/images/select_dropdown_dark.png") no-repeat
    center right 15px !important;
  border: 1px solid #222630 !important;
  color: #fff !important;
}
[data-theme="dark"] .image_dropdown_wrapper .dropdown-menu {
  background: #15272b !important;
}
[data-theme="dark"] .innerpages_wrapper::before {
  background: url("../src/assets/images/banner_bg_dark.png") no-repeat;
  background-size: cover;
}
[data-theme="dark"] .auth_wrapper .dash_box,
[data-theme="dark"] .dash_wrapper {
  border: 1px solid rgba(67, 67, 67, 0.75);
  background: rgba(29, 29, 29, 0.50);
}
[data-theme="dark"] .dash_box  {
  border: 1px solid rgba(67, 67, 67, 0.3);
  background: #040914b3;
}
[data-theme="dark"] .auth_form input.form-control,
[data-theme="dark"] .primary_asset_inp,
[data-theme="dark"] .react-tel-input .form-control {
  background: rgb(0 0 0 / 15%) !important;
  border: 1px solid #1e1e1e !important;
  color: #fff !important;
}
[data-theme="dark"] .react-tel-input .country-list {
  background-color: #131716 !important; 
}
[data-theme="dark"] .react-tel-input .country-list .search {
  background-color: #242827 !important;
}
[data-theme="dark"] .react-tel-input .country-list .search-box {
  background: rgb(0 0 0 / 15%) !important;
  border: 1px solid #1e1e1e !important;
  color: #fff !important;
}
[data-theme="dark"] .react-tel-input .country-list .country.highlight,
[data-theme="dark"] .react-tel-input .country-list .country:hover {
  background-color: #090808 !important;
}
[data-theme="dark"] .main_navbar a.nav-link.active, 
[data-theme="dark"] .main_navbar a.nav-link:hover {
  color: #e58872  !important;
}
[data-theme="dark"] .wallet_table tbody tr:nth-child(odd) {
  background: rgb(230 255 248 / 4%);
}
[data-theme="dark"] .search_grp input {
  background: rgb(0 0 0 / 15%) !important;
  border: 1px solid rgba(67, 67, 67, 0.75) !important;
  color: #fff !important;
}
[data-theme="dark"] .search_grp input::placeholder,
[data-theme="dark"] .primary_asset_inp::placeholder,
[data-theme="dark"] .staking_calc input::placeholder,
[data-theme="dark"] .react-tel-input .form-control::placeholder {
  color: #898989;
}
[data-theme="dark"] .search_icon,
[data-theme="dark"] .asset_copy_icon {
  filter: invert(1);
}
[data-theme="dark"] .primary_warning_alert {
  background: #0f1111;
  border-color: #0f1211;
}
[data-theme="dark"] .modal-content {
  background: #0f1111;
}
[data-theme="dark"] .modal-header {
  border-color: #2c2c2c; 
}
[data-theme="dark"] .modal-header .btn-close {
  filter: invert(1);
}
[data-theme="dark"] .primary_datatable .rdt_Table, 
[data-theme="dark"] .primary_datatable .rdt_Table > div,
[data-theme="dark"] .user_info_content h3,
[data-theme="dark"] .primary_modal .modal-header h1 {
  color: #fff;
}
[data-theme="dark"] .staking_calc.trade_buy {
  box-shadow: none;
  background: transparent;
  border: none;
}
[data-theme="dark"] .staking_calc .input-group .primary_asset_inp { 
  background: #0f121d !important;
}
[data-theme="dark"] .primary_asset_select {  
  border-color: #1e1e1e !important;
  background: rgb(0 0 0 / 15%) url("../src/assets/images/select_dropdown_dark.png") no-repeat;
  background-position: right 0.75rem center;
  color: #fff;
}
[data-theme="dark"] .primary_asset_select option {
  background-color: #0d0e0e !important; 
}
[data-theme="dark"] .asset_dashbox_flex_line::after { 
  background: #363737;
}
[data-theme="dark"] .primary_datatable .rdt_TableHeadRow .rdt_TableCol { 
  color: #fff;
}
[data-theme="dark"] .primary_datatable .rdt_TableBody .rdt_TableRow:nth-child(odd) {
  background: rgb(0 0 0 / 30%);
}
[data-theme="dark"] .primary_datatable .rdt_Pagination button {
  fill: #c7c7c7 !important;
}
[data-theme="dark"] .primary_datatable .rdt_Pagination button:disabled {
  fill: #818181 !important;
}
[data-theme="dark"] .nav_after_login ul.dropdown-menu {
  border: 1px solid rgb(54 54 54);
  background: rgb(15 17 16);
}
[data-theme="dark"] .nav_after_login li a.dropdown-item:focus, 
[data-theme="dark"] .nav_after_login li a.dropdown-item:hover {
  color: #fff !important;
}
[data-theme="dark"] .ticket_msg_card { 
  background: #0f1211;
  box-shadow: 0px 0px 15px 0px rgb(23 23 23 / 35%);
}
[data-theme="dark"] .ticket_msg_card_header p {
  color: #afafaf;
}
[data-theme="dark"] .user_info img {
  box-shadow: 0 15px 25px 0 #1b1b1b;
}
[data-theme="dark"] .ticker_content h3 {
  color: #fff;
}
[data-theme="dark"] .ticker_content h4 {
  color: #e39965;
}
[data-theme="dark"] .crypto_box p {
  color: #ffffff;
}
[data-theme="dark"] .crypto_box {
  background-color: rgb(0 205 249 / 2%);
  border: 1px solid #373b3c;
}
[data-theme="dark"] .coins_box >div {
  border: 1px solid rgba(67, 67, 67, 0.3);
  background: rgba(29, 29, 29, 0.30);
}

[data-theme="dark"] .coins_box .accordion-item{
  margin-bottom: 12px !important;
  border-radius: 10px !important;
  background: #070a15;
  border: 1px solid #070a15;
}
[data-theme="dark"] .coins_box .accordion-item .accordion-button{
  border-radius: 10px !important;
  background: #070a15;
  color: #ffffff;
  box-shadow: none !important;
}
[data-theme="dark"] .accordion-button::after {
  filter: contrast(0) brightness(0) invert(1) !important;
}
[data-theme="dark"] .custom_pagination .active>.page-link, 
[data-theme="dark"] .custom_pagination .page-link.active {
  background-color: #e58872;
  border-color: #e58872;
  color: #fff;
}
[data-theme="dark"] .custom_pagination .page-link {
  color: #c7c7c7;
  box-shadow: none !important;
  border: 1px solid rgba(67, 67, 67, 0.3);
  background: rgba(29, 29, 29, 0.30);
}
[data-theme="dark"] .countdown_section .bgcolor{
  background: linear-gradient(#040914, #040914) padding-box, linear-gradient(154deg, #ffffff30 13%, #ffffff00 19%, #ffffff38 57%, #ffffff33 5%, #ffffff26 88%, #ffffff1c 70%) border-box;
}
[data-theme="dark"] .countdown_section .bgcolor::after{
 background: rgba(255, 255, 255, 0.1);
}
[data-theme="dark"] .ticker_panel{
  background: linear-gradient(#040914, #040914) padding-box, linear-gradient(119deg, #ffffff38 11%, #000 20%, #ffffff00 9%, #ffffff47 57%, #ffffff52 100%) border-box;
}
[data-theme="dark"] .ticker_settings .slick-prev,
[data-theme="dark"] .ticker_settings .slick-next {
  border: 1px solid rgba(255, 255, 255, 0.3);
}
[data-theme="dark"] .ticker_settings .slick-prev:before, 
[data-theme="dark"] .ticker_settings .slick-next:before {
  background-image: url("./assets/images/arw.png");
}
[data-theme="dark"] .ticker_settings .slick-next:hover:before {
  background-image: url("./assets/images/arw.png");
  transform: rotate(180deg);
}
[data-theme="dark"] .ticker_settings .slick-prev:hover:before{
  transform: rotate(0deg);
}
[data-theme="dark"] .land_box .box{
  background: linear-gradient(#040914, #040914) padding-box,
  linear-gradient(154deg, #ffffff30 13%, #ffffff00 19%, #ffffff38 57%, #ffffff33 5%, #ffffff26 88%, #ffffff1c 70%) border-box;
}
[data-theme="dark"] .land_box .box .sub{
  color: #fff;
}
[data-theme="dark"] .land_box .box{
  color: #fff;
}
[data-theme="dark"] .land_box .box h5 + p{
  color: #fff;
}
[data-theme="dark"] .left_orange::before {
  background: #3e160d;
}
[data-theme="dark"] .land_box::after {
  background: #3e160d;
}
[data-theme="dark"] .page_header::after{
background: #3e160d;
}
[data-theme="dark"] .staking_calc label {
  color: #3e3e3e;
}
[data-theme="dark"] .staking_method_btngrp button{
  color: #fff;
border: 1px solid rgba(255, 255, 255, 0.4);
}
[data-theme="dark"] .faq_accordian .accordion-item:first-child {
  border-top: 1px solid rgb(255 255 255 / 9%);
}
[data-theme="dark"] .faq_accordian .accordion-item{
  border-bottom: 1px solid rgb(255 255 255 / 9%);
}
[data-theme="dark"] .imgbg2::before{
  opacity: 1;
}
[data-theme="dark"] .imgbg3::before{
  opacity: .04;
}
[data-theme="dark"] .main_navbar {
  background: rgb(8 15 33);
}

[data-theme="dark"] .innerpages_wrapper::before {
  background: #3e160d;
}
[data-theme="dark"] .innerpages_wrapper::after {
  background: rgba(36, 16, 137, 0.5);
}




[data-theme="light"] .page_header::before,
[data-theme="light"] .land_box::before{
  background: rgba(36, 16, 137, 0.25);
} 

[data-theme="light"] .right_blue::after{
  background: rgba(36, 16, 137, 0.2);
}
[data-theme="light"] .support-close{
  color: rgba(32, 36, 40, 0.75) !important;
}
.support_ticket_accordian .accordion-item button{
  font-size: 16px !important;
}
.ticket_title span{
  display: block;
}
.footer-links-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.footer-links-card a{
  margin-bottom: 12px;
}
.footer-links-card a:hover{
  color: #aa31f8;
}
/* Media Queries */
@media screen and (max-width: 1399px) {
  .bal{
    font-size: 14px;
  }
  .main_navbar a.nav-link{
    padding-left: 4px !important;
    padding-right: 5px !important;
  }
  .countdown_panel{
    gap: 15px;
  }
  .ticker_panel {
    margin: 0 15px;
  }
  .copy_trade_row .slick-prev:before{
     left: 12px;
     position: relative;
  }
  .dash_wrapper {
    border-radius: 30px;
    padding: 20px;
  }
  .dash_box.dash_box_right {
    padding: 36px 20px;
  }
  .dash_box_right_large_single h3 {
    font-size: 18px;
  }
  .primary_btn.primary_btn_transform {
    font-size: 14px;
    padding: 8px 12px;
  }
  .dash_box_right_top h4 {
    font-size: 16px;
  }
  .dash_box_right_bottom h2 {
    font-size: 28px;
  }
  .level_head > div:nth-child(1),
  .level_body_row > div:nth-child(1) {
    width: 32%;
  }
}
@media screen and (max-width: 1199px) {
  .page_header {
    padding-top: 100px;
  }
  .page_header_inner{
    margin-top: 0;
  }
  .page_header h1{
    font-size: 55px;
  }
  .page_header .img_box{
    min-width: unset;
    top: -16%;
    max-width: 90%;
  }
  .countdown_single h3{
    font-size: 28px;
  }
  .ticker_panel {
    margin: 0 10px;
    padding: 20px 12px;
}
.countdown_section .bgcolor::after {
  right: 0;
}
.countdown_section .bgcolor{
  padding-left: 20px;
}
  .dash_box.dash_box_left {
    height: 100%;
  }
  .dashbox_left_inner_row [class*="col-"] {
    margin-bottom: 24px;
  }
  .page_header::after {
    display: none;
  }
  .countdown_section {
    margin-top: 0;
    padding-top: 70px;
  }
  .countdown_panel {
    flex-wrap: wrap;
    justify-content: center;
  }
  .features_panel p.main_para {
    max-width: 100%;
  }
  ul.navbar_left {
    margin-left: 20px;
    display: none;
  }
  .main_navbar {
    backdrop-filter: unset;
    background: rgb(241 242 247)  ;
  }
  .navbar-toggler {
    filter: invert(1);
    box-shadow: none !important;
    border: 0;
    padding: 0;
  }
  .notify_active {
    right: -28px;
    top: 6px;
    left: unset;
  }
  .bal{
    color: #1e1e1e !important;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
  }
  .support_ticket_accordian .accordion-header .accordion-button {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .support_ticket_accordian .accordion-button::after {
    top: 30px;
  }
  .tradingview-widget-container {
    height: 500px;
  }
  ul.nav_after_login {
    align-items: flex-start;
  }
}
@media screen and (max-width: 991px) {
  .toggletheme_btn{
    width: 30px;
    height: 30px;
  }
  .land_box .mrgin{
    margin: 0;
  }
  .land_box .box{
    padding:25px 15px;
    margin-bottom: 10px;
  }
  .land_box .box h5{
    font-size: 26px;
  }
  .countdown_section .bgcolor::after{
    display: none;
  }
  .countdown_section .bgcolor{
    padding: 20px;
  }
  .countdown_panel{
    margin-bottom: 15px;
  }
  img.brand_logo {
    width: 164px;
}
  p.footer_abt_content {
    max-width: 90%;
  }
  .dash_box_right_large_single {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  .dash_box_right_large_single h5 {
    margin-bottom: 10px;
  }
  .dash_box_right_large_single::after {
    height: 100%;
  }
  .navbar_left {
    display: none;
  }
  .auth_btn_grp {
    flex-direction: column;
  }
  .auth_btn_grp .primary_btn {
    display: block;
    text-align: center;
  }
  .page_header_inner {
    text-align: center;
  }
  .footer {
    padding: 30px 0 30px;
  }
  .toolbar_bottom {
    display: block;
    position: fixed;
    bottom: 0;
    background: #e58872;
    width: 100%;
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.12);
    transition: transform 0.25s;
    z-index: 999;
  }
  .toolbar_bottom ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 15px;
    height: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .toolbar_bottom ul li svg {
    fill: #fff;
  }
  .toolbar_bottom ul li {
    text-align: center;
  }
  .toolbar_bottom ul li span {
    display: block;
    color: #fff;
    font-size: 14px;
  }
  .toolbar_bottom .navbar-toggler {
    filter: unset;
  }
  .toolbar_bottom ul li a,
  .toolbar_bottom ul li button {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    padding: 12px;
  }
  .toolbar_bottom ul li a.active {
    background: #075e3f;
  }
  .level_head > div:nth-child(1),
  .level_body_row > div:nth-child(1) {
    width: 40%;
    margin-right: 30px;
  }
  .asset_dashbox_flex {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    gap: 30px;
  }
  .asset_dashbox_flex_left,
  .asset_dashbox_flex_right {
    width: 100%;
  }
  .asset_dashbox_flex_line::after {
    display: none;
  }
  .staking_calc [class*="col-"] {
    margin-bottom: 15px;
  }
  .auth_wrapper .dash_box {
    padding: 30px;
  }
  .dash_wrapper.dash_wrapper_flex {
    flex-direction: column;
  }
  .dash_wrapper_flex_left, 
  .dash_wrapper_flex_right {
    width: 100%;
  }
  .dash_wrapper_flex::after {
    display: none;
  }
  .staking_top_flex {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }  
  .dash_box.trade_top_info {
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 50px;
    row-gap: 5px;
  }
  .coins_box {
    margin-bottom: 30px;
  }
  .page_header {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media screen and (max-width: 767px) {
  .countdown_panel{
    text-align: center;
  }
  .dash_box_right_large {
    flex-direction: column;
    align-items: flex-start;
  }
  .dash_box_right_large_single {
    width: 100%;
    flex-direction: row;
  }
  .dash_box_right_large_single::after {
    width: 100%;
    height: 1px;
    right: unset;
    top: unset;
    bottom: -15px;
  }
  .progress_note {
    flex-direction: column;
    gap: 10px;
  }
  .custom_progress .progress,
  .progress_note {
    width: 92%;
  }
  .progress_note label {
    text-align: center;
  }
  .footer_panel_bottom {
    flex-direction: column;
    text-align: center;
  }
  .footer_panel_bottom p {
    line-height: 24px;
  }
  .page_header h1 {
    font-size: 36px;
    line-height: 46px;
  }
  .countdown_single h3 {
    font-size: 32px;
    line-height: 42px;
  }
  .features_panel {
    padding: 30px 50px;
  }
  .main_title {
    font-size: 36px;
    line-height: 42px;
  }
  .features_panel h3 {
    margin: 10px 0;
  }
  .auth_wrapper {
    padding: 50px 0;
  }
  .faq_accordian .accordion-item button {
    font-size: 16px;
  }
  .level_head > div:nth-child(1),
  .level_body_row > div:nth-child(1) {
    width: 180px;
    margin-right: 10px;
  }
  .level_head > div:nth-child(3),
  .level_body_row > div:nth-child(3),
  .level_head > div:nth-child(2),
  .level_body_row > div:nth-child(2) {
    width: 150px;
    margin-right: 10px;
  }
  .deposit_asset_details > div {
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .deposit_asset_details > div label {
    width: 100%;
    line-height: 24px;
  }
  .notification_panel_row {
    flex-direction: column;
  }
  .notification_panel_row p.notify_date {
    margin-left: 15px;
  }
  .level_ribbon,
  .level_ribbon.active {
    background-size: contain;
  }
  .notify_active {
    right: -25px;
    left: unset;
  }
  .staking_method_btngrp {
    flex-wrap: wrap;
  }
  .staking_method_btngrp button {
    width: 48%;
  }
  .staking_result_div p {
    text-align: center;
  }
  .staking_result_div {
    flex-direction: column;
    padding: 30px;
    text-align: center;
  }
  .page_header_inner {
    padding: 30px;
  }
  .support_ticket_accordian .accordion-header .accordion-button > div {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .ticket_msg_card_user,
  .ticket_msg_card_admin {
    width: 100%;
  }
  .ticket_msg_card {
    padding: 15px;
  }
  .search_grp,
  .search_grp input {
    width: 100%;
  }
  .wallet_page_right_flex {
    flex-direction: column;
    align-items: flex-start;
  }
  .footer-about-card {
    text-align: center;
  }
}
@media screen and (max-width: 575px) {
  .uploaded_files_row > div span{
    max-width: 60%;
    overflow: hidden;
  }
  .uploaded_files_row{
    align-items: flex-start;
  }
  .inner_subtitle_wrap_flex {
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
  }
  .dash_box_right_large_single h3 {
    font-size: 16px;
  }
  .dash_box_right h5 {
    font-size: 14px;
  }
  .level_body_row > div,
  .level_head > div {
    font-size: 14px;
  }
  .custom_progress .progress,
  .progress_note {
    width: 85%;
  }
  .asset_dashbox_flex {
    padding: 15px;
  }
  .assets_form label,
  label.max_label {
    font-size: 14px;
  }
  .deposit_asset_details > div label {
    font-size: 15px;
  }
  .asset_transfer_flex {
    flex-wrap: wrap;
    justify-content: center;
  }
  .asset_transfer_flex img {
    transform: rotate(90deg);
  }
  .user_info {
    flex-direction: column;
    align-items: flex-start;
  }
  .staking_method_btngrp button {
    width: 100%;
    padding: 12px 15px;
  }
  .page_header h1 {
    font-size: 26px;
    line-height: 32px;
  }
  .auth_wrapper .dash_box {
    padding: 30px 15px;
  }
  .ticker_panel {
    width: 85%;
    margin: 0 auto;
    gap: 5px;
  }
  .ticker_settings .slick-prev {
    left: -17px;
    z-index: 1;
  }
  .ticker_settings .slick-next {
    right: -17px;
  }
  .ticker_panel img {
    width: 40px;
    height: 40px;
  }
  .ticker_content h3 {
    font-size: 20px;
  }
  .ticker_content h4 {
    font-size: 16px;
    gap: 10px;
  }
  .footer-links-card{
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
.verticalScroll > div:first-child{
  padding-right: 20px !important;
}
.verticalScroll > div:last-child{
  margin-right: 0px;
  opacity: 1 !important;
  background: rgba(0,0,0,.4) !important;
  width: 5px !important;
}
.verticalScroll > div:last-child > div{
  background: #e58872!important;
}
.orderbook_wrap {
  box-sizing: border-box; 
  min-width: 0px;
  width: 100%; 
  grid-area: orderbook / orderbook / orderbook / orderbook;
  position: relative;
  overflow: hidden;
  /* background: var(--dark-01); */
  /* padding-bottom: 8px; */
}

.orderbook_wrap_inner {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  /* height: 100%; */
  /* background: var(--dark-01); */
  border-radius: 0 0 10px 10px;
}

.orderbook_header {
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
  background: var(--spot_headers);
  border-bottom: 1px solid var(--grey-04);
}

.orderbook_header .orderbook_header_tips {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.orderbook_header_tips button {
  border: none;
  background: transparent;
  padding: 0;
}

.orderbook_header_tips button+button {
  margin-left: 12px;
}

.orderbook_tickSize {
  position: initial;
  right: 0px;
  flex: 1.2 1 0%;
  padding-left: 4px;
  padding-right: 4px;
  margin-left: 4px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.orderbook_tickSize select {
  border: none;
  /* background: transparent url("/assets/images/caret_arrow_icon.png") no-repeat right 0.75rem center; */
  color: red;
  /* font-size: var(--font-6); */
  box-shadow: none !important;
}

.orderbook_tbheader {
  display: flex;
  flex-direction: column;
  margin: 6px 13px 6px 0px;
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 0;
}

.orderlist_container {
  display: flex;
  flex: 1 1 0%;
  padding: 8px 0;
  flex-direction: column;
}

.orderbook_list {
  position: relative;
  display: flex;
  flex-direction: column;
  /* flex: 1 1 0%; */
  padding: 10px 0;
}

.orderbook_ticker {
  display: flex;
  padding: 1px 12px;
  height: 33px;
  -webkit-box-align: center;
  align-items: center;
  background: var(--bs-border-color-translucent);
  justify-content: space-between;
  font-size: 14px;
  color: var(--white);
}

.orderbook_tbheader .content {
  display: flex;
  height: 20px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  font-size: 12px;
  color: #707a8a;
}

.orderbook_tbody_row_bar_sell {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.orderbook_tbody_row_bar_buy {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.orderbook_list_container .orderbook_tbody_row_bar_sell {
  background: rgba(254, 83, 91, 0.2);
}


.orderbook_list_container .orderbook_tbody_row_bar_buy {
  background: rgba(56, 173, 123, 0.2);
}



.orderbook_tbheader .item {
  display: flex;
  flex: 1 1 0%;
  font-size: 14px;
  font-weight: 500;
}

.orderbook_progress .row_content {
  padding-left: 10px !important;
  padding-right: 8px !important;
}

.orderbook_progress {
  height: 22px;
  width: 100%;
  box-sizing: border-box;
}

.orderbook_list .row_content {
  display: flex;
  box-sizing: border-box;
  z-index: 2;
  width: 98%;
  height: 100%;
  line-height: 22px;
  cursor: pointer;
}

.orderbook_list .text {
  font-size: 12px;
  margin-left: 0px;
  flex: 1 1 0%;
  text-align: right;
  color: var(--white);
  cursor: pointer;
  font-weight: 300;
}

.orderbook_list .progress_container {
  height: 22px;
}

.orderlist_container.show_sell {
  padding-bottom: 0;
}

.orderlist_container.show_sell .orderbook_bid {
  display: none;
}

.orderbook_ask .orderbook_list_container {
  display: flex;
  flex-direction: column-reverse;
}

.orderlist_container.show_sell .orderbook_list_container {
  height: 767px;
}

.orderlist_container.show_buy {
  padding-bottom: 0;
}

.orderlist_container.show_buy .orderbook_ask {
  display: none;
}

.orderlist_container.show_buy .orderbook_list_container {
  height: 767px;
}

.progress_container {
  display: flex;
  flex-direction: row;
  position: relative;
  flex: 1 1 0%;
  height: 20px;
  -webkit-box-align: center;
  align-items: center;
  animation: 0.3s ease-out 0s 1 normal none running none;
  overflow: hidden;
}

.orderbook_list .ask_bar {
  background-color: var(--red);
}

/* .orderbook_list .progress_bar {
  height: 22px;
}
.orderbook_progress .progress_bar {
  position: absolute;
  right: 0px;
  z-index: 1;
  height: 20px;
  opacity: 0.1;
  width: 100%;
  left: 100%;
} */
.orderbook_list .ask_light,
.orderbook_list .bid_light {
  font-size: 12px;
  flex: 1 1 0%;
  text-align: left;
}

.orderbook_list .ask_light {
  color: var(--red);
}

.orderbook_list .bid_light {
  color: var(--green);
}

.orderbook_ticker .yellow {
  color: var(--color-01);
}

.orderbook_ticker .status_sell {
  color: var(--red);
}

.orderbook_ticker .status_green,
.orderbook_ticker .status_buy {
  color: var(--green);
}

.orderbook_ticker .status_primary {
  color: #1e2329;
}

.orderbook_ticker .contractPrice {
  display: flex;
  margin-right: 4px;
  -webkit-box-align: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
}

.orderbook_ticker .markPrice {
  flex: 1 1 0%;
  display: inline-block;
  font-size: 12px;
  text-align: left;
  color: #707a8a;
}

.orderbook_ticker .more {
  color: #707a8a;
  font-size: 12px;
  text-decoration: none;
}

.orderbook_ticker .arrow_icon {
  font-size: 16px;
}

.orderbook_list .bid_bar {
  background-color: #0ecb81;
}

.orderbook_list_container {
  position: relative;
  height: 367px;
  height: 167px;
  overflow: hidden;
  will-change: transform;
  direction: ltr;
}

.chart_wrapper {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  width: 100%;
  height: 100%;
  grid-area: chart / chart / chart / chart;
  position: relative;
}


.snip1517 {
  font-family: 'Source Sans Pro', Arial, sans-serif;
  color: #ffffff;
  text-align: left;
  font-size: 16px;
  width: 100%;
  margin: 30px 10px;
  display: flex;
  flex-grow: 1;
}

.snip1517 img {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  z-index: -1;
}

.snip1517 .plan {
  margin: 0 0.5%;
  width: 20%;
  padding-top: 10px;
  position: relative;
  overflow: hidden;
  border: 1px solid #e95f8d !important;
  background: rgb(250,236,226);
  background: linear-gradient(184deg, rgba(250,236,226,1) 0%, rgba(255,255,255,1) 50%, rgba(239,225,253,1) 100%);
  border-radius: 10px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.snip1517 .plan:hover i,
.snip1517 .plan.hover i {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.snip1517 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
}

.snip1517 header {
  color: #ffffff;
}

.snip1517 .plan-title {
  position: relative;
  margin: 0;
  padding: 0 20px;
  font-size: 1.6em;
  font-weight: 600;
  color: #e39965;
}

/* .snip1517 .plan-title:after {
  position: absolute;
  content: '';
  top: 100%;
  left: 20px;
  width: 30px;
  height: 3px;
  background-color: #fff;
} */

.snip1517 .plan-cost {
  padding: 0 20px;
  margin: 0;
}

.snip1517 .plan-price {
  font-weight: 400;
  font-size: 1.8em;
  margin: 10px 0;
  display: inline-block;
  color: #e86a85;
}

.snip1517 .plan-type {
  opacity: 0.8;
  font-size: 0.7em;
  text-transform: uppercase;
}

.snip1517 .plan-features {
  padding: 0 0 20px;
  margin: 0;
  list-style: outside none none;
  font-size: 0.9em;
}

.snip1517 .plan-features li {
  padding: 8px 20px;
  color: #333333;
}

.snip1517 .plan-features i {
  margin-right: 8px;
  color: rgba(255, 255, 255, 0.5);
}

.snip1517 .plan-select {
  border-top: 1px solid rgb(224, 203, 244);
  padding: 20px;
  text-align: center;
}

.snip1517 .plan-select a {
  background-color: #156dab;
  color: #ffffff;
  text-decoration: none;
  padding: 12px 20px;
  font-size: 0.75em;
  font-weight: 600;
  border-radius: 8px;
  text-transform: uppercase;
  display: inline-block;
}

.snip1517 .plan-select a:hover {
  background-color: #1b8ad8 !important;
}

.snip1517 .featured {
  margin-top: -10px;
  z-index: 1;
  border-radius: 8px;
  border: 2px solid #156dab;
  background-color: #156dab;
}

.snip1517 .featured .plan-select {
  padding: 30px 20px;
}

.snip1517 .featured .plan-select a {
  background-color: #10507e;
}
.dash_wrapper img {
  border-radius: 10px;
}
.dash_wrapper h1 {
  font-weight: 600;
  font-size: 36px;
  margin-bottom: 24px;
}
@media only screen and (max-width: 1199px) {
  .snip1517 .plan {
    width: 100%;
  }
  .snip1517{
    flex-wrap: wrap;
    gap: 10px;
  }
  .snip1517 .plan-features{
    display: flex;
    flex-wrap: wrap;
  }
  .snip1517 .plan-features li{
    width: 33%;
  text-align: left;
  }
  .snip1517 .plan-features i{
    margin-right: 0px !important;
  }
}
@media only screen and (max-width: 767px) {
  .snip1517 .plan {
    padding: 10px;
    margin: 1.5%;
  }
  .snip1517 header{
    padding: 8px;
  }
  .snip1517 .plan-title,
  .snip1517 .plan-select a {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  .snip1517 .plan-select,
  .snip1517 .featured .plan-select {
    padding: 20px;
  }
  .snip1517 .featured {
    margin-top: 0;
  }
  .snip1517 .featured header {
    line-height: 70px;
  }
  
}

@media only screen and (max-width: 440px) {
  .snip1517 .plan {
    margin: 0.5% 0;
    width: 100%;
  }
}
@media only screen and (max-width: 575px) {
  .snip1517 .plan-features li {
    text-align: center;
    width: 100%;
  }
}

.border_btn {
  padding: 6px 20px;
  font-size: 16px;
  text-transform: capitalize;
  color: #e58872;
  border: 1px solid #e58872;
  border-radius: 20px;
  background: transparent;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  font-family: "Gilroy";
  font-weight: 600;
}
.border_btn:hover {
  color: #fff;
  border: 1px solid #e58872;
  background: #e58872;
}
.chart-group-button .primary_btn:hover{
  box-shadow: none;
  color: #fff;
  border: 1px solid #e58872;
  background: #e58872;
}
.market_pair {
  display: flex;
  align-items: center;
  gap: 10px;
}
.market_pair img {
  width: 32px;
}
.market_pair p {
  margin-bottom: 0;
  color: #e58872;
}
.market_pair p span {
  color: #626262;
  font-size: 14px;
  margin-left: 2px;
}